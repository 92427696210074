<template>
    <div class="dashboard">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-img lazy-src="img/smoking.jpg" src="img/smoking.jpg" aspect-ratio="0.9" height="250"
            style="margin-top: -110px"></v-img>
        <v-container>
            <div class="pa-2 mt-1">
                <v-layout column>
                    <v-flex xs12>
                        <v-expansion-panels accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    How Do I Receive My Free Gift?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    First you have to go to the shop now button and this will take you to the luxury
                                    brand brand
                                    bracelet of your choice. Each bracelet will come with different measurements of
                                    gifts.
                                    Choose which bracelets you want wisely and this will take you to go pick your free
                                    gift
                                    prior to check out. That simple
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Do You Guys Take Any Form Or Electronic Payment (e.g. Cashapp, Credit, Debit, etc)?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    NO WE ONLY ACCEPT CASH!
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Why Are You Guys So Cheap?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Well we grow everything in house. We never have to go thru a middle man. We control the
                            whole process from seed to sale. So this way we are able to share the savings ok to our
                            customer and give so much out for free consistently!
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Why Do You Guys Need My ID And Selfies?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    We use this to verify customer identity and age. We want to stay in compliance with all
                            laws so we require this information at the set up of your account.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Why My Driver Couldn’t Come Directly To The Address I Sent Him?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Because where they pull up to May not be safe for them so we leave it at the drivers
                            discretion on how close he will come to that address. But we make our best effort to be
                            within walking distance.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Why Do I Pay A Deliver Fee?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Well the driver have to make their money because they are using they’re own car. The
                            delivery fee goes directly to the driver. DISTRXCT does not receive any of that money. And
                            as well all know these gas prices are getting out of hand.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    How Do I Avoid A Delivery Fee or My Discounted Prices Being Increased ?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    All you have to do is refer three new customers a month and you will have free deliveries
                            for the following 30 days. After the 3rd person is referred. All referrals must make atleast
                            make one purchase of any kind of bracelets. And must input your referral code at the
                            beginning of starting their account with the Distrxct. Or this may not account towards your
                            delivery credit.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    How Does The Point System Works?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    For every dollar you spend you will get 10 points added to your account. With these points
                            you are able to purchase products with no cash and only points. This does not include
                            delivery fee.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Where Do You Guys Deliver?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Within DC and the surrounding areas. If you are out of the territory where we deliver you
                            are free to do a meet up within these areas.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Are There Refunds?
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    ALL SALES ARE FINAL.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-flex>
                </v-layout>
            </div>
        </v-container>


        <AuthPopUp v-model="loginCardState" />
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        AuthPopUp,
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            loading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,
            dialog: false,
            snackbar: false,
        };
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        this.closeNav()
        window.scrollTo(0, 0);
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
    },
    methods: {
        ...mapActions([
            "closeNav",
            "changeLoginCardState",
            "changeHeaderColor",
            "changeTitleColor",
        ]),
        changeColor() {
            if (
                document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100
            ) {
                this.changeHeaderColor("black");
                this.changeTitleColor("white--text");
                this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
            } else {
                this.changeHeaderColor("transparent");
                this.changeTitleColor("white--text");
                this.changeButtonColor(
                    "white--text hidden-sm-and-down text-none caption"
                );
            }
        },
    },
    computed: {
        ...mapGetters([
            "isAuthenticated",
            "loginCardState",
        ]),
    },
};
</script>